// src/pages/index.js
import OrganizationsContent from "@/admin/components/data-display/OrganizationsContent";
import PropertiesContent from "@/client/components/data-display/PropertiesContent";
import ProtectedHomepage from "@/shared/components/auth/ProtectedHomepage";
import ErrorMessage from "@/shared/components/feedback/ErrorMessage";
import AuthPageHead from "@/shared/components/marketing/AuthPageHead";
import { useUser } from "@auth0/nextjs-auth0/client";
import { useMemo } from "react";

const HomePage = () => {
	// Fetch user details using the useUser hook
	const { user: auth0User, isError: isErrorAuth0User, isLoading: isLoadingAuth0User } = useUser();

	const isRentIQAdmin = useMemo(() => {
		const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;
		return auth0User && auth0User[namespace]?.includes("RentIQAdmin");
	}, [auth0User]);

	if (isErrorAuth0User) {
		return <ErrorMessage />;
	}

	if (isLoadingAuth0User) {
		return null;
	}

	return (
		<ProtectedHomepage>
			<AuthPageHead />
			<div className="bg-off-white">
				<div className="max-w-5xl mx-auto px-4 py-3 main-content overflow-x-auto">
					{isRentIQAdmin ? <OrganizationsContent /> : <PropertiesContent />}
				</div>
			</div>
		</ProtectedHomepage>
	);
};

HomePage.displayName = "HomePage";

export default HomePage;
