// src/shared/components/buttons/LinkButton.js
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";

// Component has a right chevron by default, but will set that as false when the link is a link backward
// E.g. on a back page
const LinkButton = ({ href, children, hasRightChevron = true }) => {
	return (
		<Link href={href} className="inline-block">
			<span className="btn bg-black hover:bg-gray-800 transition duration-300 text-white px-4 py-2 rounded font-semibold flex items-center group">
				{children}
				{hasRightChevron && (
					<ChevronRightIcon className="ml-2 h-5 w-5 transition-transform duration-300 transform group-hover:translate-x-1" />
				)}
			</span>
		</Link>
	);
};

export default LinkButton;
