// src/client/components/skeletons/PropertiesContentSkeleton.js

const PropertiesContentSkeleton = () => {
	return (
		<div className="p-8 bg-off-white min-h-screen animate-pulse">
			<div className="h-8 bg-gray-300 w-48 mb-4 rounded"></div>
			<div className="w-full md:w-1/2 mb-6">
				<div className="h-10 bg-gray-300 w-full rounded"></div>
			</div>
			<div className="space-y-4">
				{[...Array(5)].map((_, index) => (
					<div key={index} className="bg-white p-4 rounded-lg shadow">
						<div className="h-6 bg-gray-300 w-3/4 mb-2 rounded"></div>
						<div className="h-4 bg-gray-300 w-1/2 mb-2 rounded"></div>
						<div className="h-4 bg-gray-300 w-1/4 rounded"></div>
					</div>
				))}
			</div>
		</div>
	);
};

export default PropertiesContentSkeleton;
