// src/shared/components/auth/ProtectedHomepage.js
import ProtectedPage from "@/shared/components/auth/ProtectedPage";

export default function ProtectedHomepage({ children }) {
	return (
		<ProtectedPage title="Welcome to the Rent IQ App" description="Please sign in below">
			{children}
		</ProtectedPage>
	);
}
